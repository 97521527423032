import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Layout from 'layouts/Default'
import HeaderSection from 'sections/Jobs/header'
import ListingSection from 'sections/Jobs/listing'
import CollageSection from 'sections/Jobs/components/collage'
import JoinButton from 'sections/Jobs/components/joinButton'
import { graphql } from 'gatsby'
import styled from 'styled-components'

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 298px - 92px);
`

const Jobs = ({ data, ...restProps }) => {
  const buildOGMetadata = () => {
    const { elements } = data.kenticoCloudItemJobs

    return {
      title: elements.page_metadata__opengraph_title.value,
      imageUrl: elements.page_metadata__opengraph_image.value[0].url,
      description: elements.page_metadata__opengraph_description.value,
    }
  }

  return (
    <Layout {...restProps} ogMetadata={buildOGMetadata()}>
      <Helmet>
        <link
          rel="canonical"
          href={`${process.env.GATSBY_ROBOTS_SITE_URL}/careers`}
        />
      </Helmet>
      <PageContainer>
        <HeaderSection {...data.kenticoCloudItemJobs} />
        <JoinButton />
        <CollageSection {...data.kenticoCloudItemJobs} />
        <ListingSection {...data.kenticoCloudItemJobs} />
      </PageContainer>
    </Layout>
  )
}

export default Jobs

Jobs.propTypes = {
  data: PropTypes.object,
}

export const query = graphql`
  {
    kenticoCloudItemJobs {
      elements {
        title {
          value
        }
        tagline {
          value
        }
        collage_desktop {
          value {
            url
          }
        }
        collage_phone {
          value {
            url
          }
        }
        jobs {
          elements {
            title {
              value
            }
            description {
              value
            }
            location {
              value
            }
            cta_text {
              value
            }
            cta_link {
              value
            }
            slug {
              value
            }
          }
        }
        page_metadata__opengraph_title {
          value
        }
        page_metadata__opengraph_image {
          value {
            url
          }
        }
        page_metadata__opengraph_description {
          value
        }
      }
    }
  }
`
