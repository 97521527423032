import React from 'react'
import Button from 'components/Button'
import { color } from 'const/colors'
import styled from 'styled-components'
import Container from 'components/Container'
import media from 'styles/media'
if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]')
}
const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 45px;

  ${media.phone`
    margin-bottom: -14px;
  `}
`

const ButtonStyled = styled(Button)`
  margin-left: 0;
`

class joinButton extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <Wrapper>
        <Container>
          <a href="#current-openings">
            <ButtonStyled
              color={color.primary.o100}
              secondaryColor={color.primary.o100}
            >
              Join our team ↓
            </ButtonStyled>
          </a>
        </Container>
      </Wrapper>
    )
  }
}

export default joinButton
