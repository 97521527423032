import React from 'react'
import PropTypes from 'prop-types'
import Section from 'components/Section'
import styled from 'styled-components'
import { color } from 'const/colors'
import media from 'styles/media'

const Title = styled.div`
  color: ${color.black.o100};
  font-size: 24px;
  line-height: 40px;
  display: flex;

  ${media.tablet`
    font-size: 24px;
    line-height: 40px;
  `}
`

const Location = styled.div`
  color: ${color.black.o100};
  opacity: 0.75;
  font-size: 24px;
  line-height: 40px;
  display: flex;
  color: ${color.primary.o100};
`

const InfoSection = styled(Section)`
  padding: 48px 0px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${color.black.o100};
  flex-direction: row;

  ${media.tablet`
    padding: 34px 0px;
  `}

  ${media.phone`
    flex-direction: column;
    padding: 20px 0px;
  `}
`

const ServiceInfo = ({ title, location, link }) => {
  return (
    <a href={link}>
      <InfoSection>
        <Title>{title}</Title>
        <Location>{location}</Location>
      </InfoSection>
    </a>
  )
}

export default ServiceInfo

ServiceInfo.propTypes = {
  title: PropTypes.string,
  location: PropTypes.string,
  link: PropTypes.string,
}
