import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import media from 'styles/media'
import Container from 'components/Container'
import withCurrentBreakpoint from 'hoc/withCurrentBreakpoint'

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;

  ${media.tablet`
    margin-bottom: 44px;
  `}
`

const ImgDesktop = styled.img`
  width: 100%;
`

const ImgMobile = styled.img`
  width: 100%;
`

const CollageSection = ({
  elements: { collage_desktop, collage_phone },
  currentBreakpoint,
}) => {
  const srcPhone = `${collage_phone.value[0].url}?w=720`
  const srcSmall = `${collage_desktop.value[0].url}?w=1080`
  const srcMedium = `${collage_desktop.value[0].url}?w=1280`
  const srcLarge = `${collage_desktop.value[0].url}?w=1620`

  return (
    <Wrapper>
      <Container>
        {currentBreakpoint === 'phone' ? (
          <>
            {collage_phone && (
              <picture>
                <source
                  srcSet={srcPhone}
                  media="(min-width: 0px) and (max-width: 767px)"
                ></source>
                <source srcSet={srcPhone}></source>
                <ImgMobile src={srcPhone} />
              </picture>
            )}
          </>
        ) : (
          <>
            {collage_desktop && (
              <picture>
                <source
                  media="(min-width: 768px) and (max-width: 1279px) and (min-resolution: 190dpi)"
                  srcSet={srcMedium}
                ></source>
                <source
                  media="(min-width: 1280px) and (min-resolution: 190dpi)"
                  srcSet={srcLarge}
                ></source>
                <source
                  media="(min-width: 768px) and (max-width: 1079px)"
                  srcSet={srcSmall}
                ></source>
                <source
                  media="(min-width: 1080) and (max-width: 1279px)"
                  srcSet={srcMedium}
                ></source>
                <source media="(min-width: 1280px)" srcSet={srcLarge}></source>
                <source srcSet={srcLarge}></source>
                <ImgDesktop src={srcLarge} />
              </picture>
            )}
          </>
        )}
      </Container>
    </Wrapper>
  )
}

CollageSection.propTypes = {
  elements: PropTypes.object,
  currentBreakpoint: PropTypes.string,
}

export default withCurrentBreakpoint(CollageSection)
