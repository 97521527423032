import React from 'react'
import PropTypes from 'prop-types'
import Section from 'components/Section/headerSection'

const HeaderSection = ({ elements: { title, tagline } }) => (
  <Section header richText headline={title.value} tagline={tagline.value} />
)

export default HeaderSection

HeaderSection.propTypes = {
  elements: PropTypes.object,
}
